import { InstrumentIcon } from 'main/schemas/PaymentInstrument';
import { FC } from 'react';

import { AmericanExpress } from './PaymentIcons/AmericanExpress';
import { ApplePay } from './PaymentIcons/ApplePay';
import { Bancontact } from './PaymentIcons/Bancontact';
import { BankTransfer } from './PaymentIcons/BankTransfer';
import { Delivery } from './PaymentIcons/Delivery';
import { DinnersClubInt } from './PaymentIcons/DinnersClubInt';
import { DirectDebit } from './PaymentIcons/DirectDebit';
import { Discover } from './PaymentIcons/Discover';
import { ECommerceConnect } from './PaymentIcons/ECommerceConnect';
import { Elo } from './PaymentIcons/Elo';
import { GenericCard } from './PaymentIcons/GenericCard';
import { GooglePay } from './PaymentIcons/GooglePay';
import { Ideal } from './PaymentIcons/Ideal';
import { JBC } from './PaymentIcons/JBC';
import { Maestro } from './PaymentIcons/Maestro';
import { MasterCard } from './PaymentIcons/MasterCard';
import { NuveiTwint } from './PaymentIcons/NuveiTwint';
import { OnlinePayment } from './PaymentIcons/OnlinePayment';
import { PayPal } from './PaymentIcons/PayPal';
import { SEPA } from './PaymentIcons/SEPA';
import { SubsidiaryPay } from './PaymentIcons/SubsidiaryPay';
import { TransbankWebpay } from './PaymentIcons/TransbankWebpay';
import { UnionPay } from './PaymentIcons/UnionPay';
import { Visa } from './PaymentIcons/Visa';

interface PaymentBrandIconProps {
  readonly icon: InstrumentIcon;
}

export const PaymentIcon: FC<PaymentBrandIconProps> = ({ icon }) => {
  switch (icon) {
    case InstrumentIcon.Amex:
      return AmericanExpress('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Diners:
      return DinnersClubInt('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Discover:
      return Discover('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Elo:
      return Elo('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Jcb:
      return JBC('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.MasterCard:
      return MasterCard('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Maestro:
      return Maestro('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.UnionPay:
      return UnionPay('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Visa:
      return Visa('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.PayPal:
      return PayPal('w-5 h-9 self-center flex-none inline-block');
    case InstrumentIcon.DirectDebit:
      return DirectDebit('w-7 h-9 self-center flex-none inline-block');
    case InstrumentIcon.TransbankWebpay:
      return TransbankWebpay('w-16 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Subsidiary:
      return SubsidiaryPay('w-7 h-9 self-center flex-none inline-block');
    case InstrumentIcon.GenericCard:
      return GenericCard('w-8 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Ideal:
      return Ideal('w-8 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Bancontact:
      return Bancontact('w-12 h-9 self-center flex-none inline-block');
    case InstrumentIcon.ApplePay:
      return ApplePay('w-11 h-9 self-center flex-none inline-block');
    case InstrumentIcon.GooglePay:
      return GooglePay('w-12 h-9 self-center flex-none inline-block');
    case InstrumentIcon.ECommerceConnect:
      return ECommerceConnect('w-9 h-9 self-center flex-none inline-block');
    case InstrumentIcon.NuveiTwint:
      return NuveiTwint('w-12 h-9 self-center flex-none inline-block');
    case InstrumentIcon.SEPA:
      return SEPA('w-12 h-9 self-center flex-none inline-block');
    case InstrumentIcon.Delivery:
      return Delivery('w-8 h-9 self-center flex-none inline-block');
    case InstrumentIcon.OnlinePayment:
      return OnlinePayment('w-12 h-9 self-center flex-none inline-block');
    case InstrumentIcon.BankTransfer:
      return BankTransfer('w-12 h-9 self-center flex-none inline-block');
  }
};
