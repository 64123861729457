export enum ApiModuleName {
  BraintreeApplePay = 'BraintreeApplePay',
  BraintreeCard = 'BraintreeCreditCard',
  BraintreeGooglePay = 'BraintreeGooglePay',
  BraintreePayPal = 'BraintreePaypal',
  DirectDebitLSV = 'DirectDebitLSV',
  WorldpayBancontact = 'BancontactWorldpay',
  WorldpayCard = 'CreditCardWorldpay',
  WorldpayIdeal = 'IdealWorldpay',
  PaymentAtSubsidiary = 'PaymentInSubsidiary',
  Sepa = 'DirectDebitSEPA',
  Transbank = 'Transbank',
  ECommerceConnect = 'ECommerceConnect',
  NuveiTwint = 'NuveiTwint',
  FlutterwaveCreditCard = 'FlutterwaveCreditCard',
  NuveiCreditCard = 'NuveiCreditCard',
  BraintreeSepa = 'BraintreeSepa',
  PaymentOnDelivery = 'PaymentOnDelivery',
  BankTransfer = 'BankTransfer',
  Finvoice = 'Finvoice',
  TwoCTwoP = 'TwoCTwoP',
  ProcessingKZ = 'ProcessingKZ',
}
