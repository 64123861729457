import { DemoDrawer } from 'main/components/pages/dev-only/demo';
import { PaymentMethodsPage } from 'main/components/pages/payment-methods/PaymentMethodsPage';
import { SessionExpired } from 'main/components/pages/SessionExpired';
import { PaymentProcessing } from 'main/components/pages/status-pages/PaymentProcessing';
import { PaymentSuccessful } from 'main/components/pages/status-pages/PaymentSuccessful';
import { StoredInstrumentsPage } from 'main/components/pages/stored-intruments/StoredInstrumentsPage';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { isProdBuild } from 'main/utils/env';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorHandler } from './components/common/errors/ErrorHandler';
import { BankTransferDetailsPage } from './components/pages/bank-transfer/BankTransferDetailsPage';
import { FinvoiceDetailsPage } from './components/pages/finvoice/FinvoiceDetailsPage';
import { PaymentOnDeliveryDetailsPage } from './components/pages/payment-on-delivery/PaymentOnDeliveryDetailsPage';
import PaymentStore from './services/payments/PaymentStore';

function PaymentIntentApp() {
  const { t } = useTranslation();

  const [internalPage, setInternalPage] = useState<InternalPage>();

  const pageToRender = useMemo(() => {
    const successUrl = PaymentStore.successUrl;
    switch (internalPage) {
      case InternalPage.PaymentMethods:
        return <PaymentMethodsPage flow={PaymentStore.flow} />;
      case InternalPage.StatusError:
        return <ErrorHandler />;
      case InternalPage.StatusProcessing:
        return (
          <PaymentProcessing
            orderId={PaymentStore.orderNumber}
            title={
              PaymentStore.flow === PaymentIntentFlow.Manage
                ? t('Adding new payment method...')
                : t('Processing')
            }
          />
        );
      case InternalPage.StatusSuccess:
        return (
          <PaymentSuccessful
            orderId={PaymentStore.orderNumber}
            title={t('Payment successful!')}
            successUrl={successUrl}
          />
        );
      case InternalPage.StoredInstruments:
        return (
          <StoredInstrumentsPage
            instruments={PaymentStore.instruments}
            flow={PaymentStore.flow}
          />
        );
      case InternalPage.SessionExpired:
        return <SessionExpired />;
      case InternalPage.BankTransfer:
        return <BankTransferDetailsPage />;
      case InternalPage.PaymentOnDelivery:
        return <PaymentOnDeliveryDetailsPage />;
      case InternalPage.Finvoice:
        return <FinvoiceDetailsPage />;
      case InternalPage.FinvoiceStatusSuccess:
        return (
          <PaymentSuccessful
            orderId={PaymentStore.orderNumber}
            title={t(
              'Your order will be processed after we have received your payment!'
            )}
            successUrl={successUrl}
          />
        );
      case InternalPage.PaymentOnDeliveryStatusSuccess:
        return (
          <PaymentSuccessful
            orderId={PaymentStore.orderNumber}
            title={t(
              'Pay for your order with Cash or Card once it is delivered'
            )}
            successUrl={successUrl}
          />
        );
      case InternalPage.PaymentInSubsidiaryStatusSuccess:
        return (
          <PaymentSuccessful
            orderId={PaymentStore.orderNumber}
            title={t(
              'Your order will be ready for pickup from the selected subsidiary. Payment for the order must be done at the time of the pickup'
            )}
            successUrl={successUrl}
          />
        );
      default:
        return null;
    }
  }, [internalPage, t]);

  useEffect(() => {
    const navigationSubscription = NavigationService.subscribe(setInternalPage);
    return () => navigationSubscription.unsubscribe();
  }, []);

  return (
    <>
      {!isProdBuild && <DemoDrawer />}
      {pageToRender}
    </>
  );
}

export default PaymentIntentApp;
