import {
  PaymentModuleId,
  InstrumentIcon,
} from 'main/schemas/PaymentInstrument';

export const InstrumentIconForModuleId: {
  [k in PaymentModuleId]: InstrumentIcon;
} = {
  [PaymentModuleId.BraintreeCard]: InstrumentIcon.GenericCard,
  [PaymentModuleId.WorldpayCard]: InstrumentIcon.GenericCard,
  [PaymentModuleId.BraintreePayPal]: InstrumentIcon.PayPal,
  [PaymentModuleId.Transbank]: InstrumentIcon.TransbankWebpay,
  [PaymentModuleId.PaymentAtSubsidiary]: InstrumentIcon.Subsidiary,
  [PaymentModuleId.Sepa]: InstrumentIcon.DirectDebit,
  [PaymentModuleId.WorldpayIdeal]: InstrumentIcon.Ideal,
  [PaymentModuleId.BraintreeApplePay]: InstrumentIcon.ApplePay,
  [PaymentModuleId.WorldpayBancontact]: InstrumentIcon.Bancontact,
  [PaymentModuleId.BraintreeGooglePay]: InstrumentIcon.GooglePay,
  [PaymentModuleId.DirectDebitLSV]: InstrumentIcon.DirectDebit,
  [PaymentModuleId.ECommerceConnect]: InstrumentIcon.ECommerceConnect,
  [PaymentModuleId.NuveiTwint]: InstrumentIcon.NuveiTwint,
  [PaymentModuleId.FlutterwaveCreditCard]: InstrumentIcon.GenericCard,
  [PaymentModuleId.NuveiCreditCard]: InstrumentIcon.GenericCard,
  [PaymentModuleId.BraintreeSepa]: InstrumentIcon.SEPA,
  [PaymentModuleId.PaymentOnDelivery]: InstrumentIcon.Delivery,
  [PaymentModuleId.BankTransfer]: InstrumentIcon.BankTransfer,
  [PaymentModuleId.Finvoice]: InstrumentIcon.DirectDebit,
  [PaymentModuleId.TwoCTwoP]: InstrumentIcon.OnlinePayment,
  [PaymentModuleId.ProcessingKZ]: InstrumentIcon.OnlinePayment,
};
