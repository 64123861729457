import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { Startupable } from 'main/services/base/Startupable';
import { registerStartupable } from 'main/services/base/StartupHelper';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import UUIDService from 'main/services/UUIDService';

import { setClarityTag } from '../clarity';
import PaymentStore from '../payments/PaymentStore';
import { NavigationSubscription } from './NavigationSubscription';

export enum InternalPage {
  PaymentMethods = 'PaymentMethods',
  StatusProcessing = 'StatusProcessing',
  StatusSuccess = 'StatusSuccess',
  StatusError = 'StatusError',
  StoredInstruments = 'StoredInstruments',
  SessionExpired = 'SessionExpired',
  BankTransfer = 'BankTransfer',
  Finvoice = 'Finvoice',
  FinvoiceStatusSuccess = 'FinvoiceStatusSuccess',
  PaymentOnDelivery = 'PaymentOnDelivery',
  PaymentOnDeliveryStatusSuccess = 'PaymentOnDeliveryStatusSuccess',
  PaymentInSubsidiaryStatusSuccess = 'PaymentInSubsidiaryStatusSuccess',
}

type CallbackFn = (currentPage: InternalPage) => void;

class NavigationService implements Startupable {
  public readonly name = 'NavigationService';

  private observers: Record<string, CallbackFn> = {};
  private currentPage: InternalPage = InternalPage.StoredInstruments;

  constructor() {
    registerStartupable(this, [UUIDService, PaymentStore]);
  }

  public startup(): Promise<void> {
    this.observers = {};
    this.updateCurrentPageAndTrackPageView(this.computeInitialPage());
    return Promise.resolve();
  }

  public subscribe(callback: CallbackFn, customId?: string) {
    const id = customId ?? UUIDService.createId();

    if (this.observers[id]) {
      Logger.warn(
        LoggerOrigin.NavigationService,
        `The id "${id}" is already taken! Beware the callback will be overwritten!`
      );
    }

    this.observers[id] = callback;
    callback(this.currentPage);
    return new NavigationSubscription(id);
  }

  public unsubscribe(id: string) {
    delete this.observers[id];
  }

  public getInternalPage() {
    return this.currentPage;
  }

  public navigateTo(page: InternalPage) {
    if (this.currentPage !== page) {
      this.updateCurrentPageAndTrackPageView(page);
      Logger.log(
        LoggerOrigin.NavigationService,
        `Updated currentPage to "${this.currentPage}"`
      );
      this.notifyObservers();
      setClarityTag('navigation', page);
    }
  }

  private updateCurrentPageAndTrackPageView(page: InternalPage) {
    this.currentPage = page;
    TelemetryService.trackPageView({ name: this.currentPage });
  }

  private notifyObservers() {
    Object.keys(this.observers).forEach((id) => {
      try {
        this.observers[id](this.currentPage);
      } catch (err) {
        Logger.error(
          LoggerOrigin.NavigationService,
          `Failed to notify "${id}". Don't forget to unsubscribe! This time I will do it for you.`,
          { cause: err }
        );
        this.unsubscribe(id);
      }
    });
  }

  private computeInitialPage() {
    // For moto flow start the application in the list of methods
    // In case of empty list of stored instruments, start in the list of methods
    if (
      this.isMotoFlow(PaymentStore.flow) ||
      this.isEmptyListOfInstruments(PaymentStore.instruments) ||
      this.hasNewMethodPreSelected(
        PaymentStore.rawPreSelectedPaymentMethod,
        PaymentStore.preSelectedInstrument
      )
    ) {
      return InternalPage.PaymentMethods;
    }

    return InternalPage.StoredInstruments;
  }

  private isMotoFlow(flow: PaymentIntentFlow) {
    return flow === PaymentIntentFlow.Moto;
  }

  private isEmptyListOfInstruments(
    instruments: ReadonlyArray<PaymentInstrument>
  ) {
    return instruments.length === 0;
  }

  private hasNewMethodPreSelected(
    rawPreSelection: PaymentModuleId | undefined,
    preSelectedInstrument: PaymentInstrument | undefined
  ) {
    return rawPreSelection !== undefined && preSelectedInstrument === undefined;
  }
}

export default new NavigationService();
